/*
=============== 
Variables
===============
*/
:root {
  --clr-white: #fff;
  --clr-black: #000;
  --transition: all 0.3s linear; 
  --spacing: 0.1rem;
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
  color:var(--clr-white);
  font-family: lato;
  font-weight: normal;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.5rem;
  color: var(--clr-black);
  font-size: 1.1rem;
}


@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */
/* section */
.section {
  padding: 1rem 0;
  scroll-margin-top: 4rem;
  border-bottom: 0.2rem solid var(--clr-white);
}
.section:nth-child(even) {background-color:#000;}
.section:nth-child(odd) {background-color:#fff;}

.section-title span {
  color: var(--clr-white);
}
.sectionDiv {margin-top:1rem; margin-bottom:2.5rem;}

/*
=============== 
Hero
===============
*/

.hero {
  min-height: 100vh;
  background: var(--clr-white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.hero-banner {
  text-align: center;
  color: var(--clr-white);
  padding: 0 3rem;
}
.hero-banner p {
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
}
  .hero {
    background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
      url('./images/background-2025.jpg') center/cover no-repeat;
  }
  .hero-banner {
    padding: 0;
  }
  .hero-banner p {
    max-width: 30rem;
  }
  .hero-banner img {width:50%;}

  @media screen and (max-width: 800px) {
  .hero {
    min-height: 88vh;
  }
  .hero {
    background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
      url('./images/background-2025.jpg') center/cover no-repeat;
  }
  }

/*
=============== 
Footer
===============
*/
.footer {
  background: var(--clr-grey-1);
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  border-top: 0.2rem solid var(--clr-white);
  border-bottom: 0.2rem solid var(--clr-white);
}
.footer-links,
.footer-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}
.footer-link {
  color: var(--clr-white);
  text-transform: capitalize;
  font-size: 1.2rem;
  padding:1rem;
  letter-spacing: var(--spacing);
  transition: var(--transition);
}
.footer-link:hover {
  color: var(--clr-white);
}
.footer-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--clr-white);
  transition: var(--transition);
}
.footer-icon:hover {
  color: var(--clr-white);
}

@media screen and (max-width: 800px) {
  .footer-links li {
    margin-bottom:1.2rem;
  }
  }

.copyright {
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  color: var(--clr-white);
}
.copyright span {
  margin-left: 0.5rem;
}
/*
=============== 
Navbar
===============
*/

.nav-icons {
  display: none;
}
.nav-center {
  max-width: 1240px;
  margin: 0 auto;
}
.nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-link {
  display: block;
  padding: 1rem 2rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  transition: var(--transition);
  color: var(--clr-white);
  cursor: pointer;
  font-size: 1rem;
}
.nav-link:hover {
  color: var(--clr-black);
  background: var(--clr-primary-8);
  padding-left: 2.25rem;
  font-weight: 600;
}

.nav-header img {width:25%; min-width: 100px;}

@media screen and (max-width: 1265px){
.nav-header img {margin: 1% 1%;}
.nav-icons i.fab {margin: 1% 1%;}
}

@media screen and (max-width: 1167px) {
  .navbar {
    height:6rem;
  }
  .nav-header {
    padding: 0.4rem 0;
  }

}

h2.sectionHeader {margin-bottom:0;}

  /* show links */
  .nav-links {
    height: auto;
    display: flex;
  }
  .nav-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .nav-header {
    padding: 0 0;
  }
  .nav-link {
    padding: 0 0;
  }
  .nav-link:hover {
    padding: 0;
    color: var(--clr-white);
    background: transparent;
  }
  .nav-icons {
    display: flex;
  }

  .nav-link {
    margin-right: 1.7rem;
  }
  .nav-icon {
    margin-right: 1rem;
    color: var(--clr-white);
    font-size: 1.4rem;
    transition: var(--transition);
  }
  .nav-icon:hover {
    color: var(--clr-white);
    transform: scale(1.2);
  }

  @media screen and (max-width: 1230px) {
  .nav-links {display: none;}
  .nav-toggle {display: block;}
  .sideBar {display: block;}
  }

  @media screen and (max-width: 680px) {
    .nav-icons {display: none;}
    }



  /* Gallery and Splide*/
  #gallery .splide .splide__track {width:50%; margin: auto;}
  .splide .splide__arrow--prev {left:20%; top:45%;}
  .splide .splide__arrow--next {right:20%; top:45%;}
  .splide .splide__pagination {position: relative; margin: 0 0 2rem 0;}
  #gallery h2 {    
    text-align: center;  
    margin-bottom:0;  
  }
  #gallery p {
    color:var(--clr-white);
    text-align: center;
    padding-top: 2%;
    font-size:15px;
  }

  #gallery .imgDesc {width:75%; margin:auto;}

  @media screen and (max-width: 1200px) {
  #gallery .splide .splide__track {width:90%; margin: auto;}
  .splide .splide__arrow--prev {left:5%; top:45%;}
  .splide .splide__arrow--next {right:5%; top:45%;}
  }

  @media screen and (max-width: 750px) {
    .splide .splide__arrow--prev {display:none;}
    .splide .splide__arrow--next {display: none;}
    #gallery .imgDesc {width:90%; margin:auto;}
    }


  #hallOfFame h2 {color:#000; margin:auto; text-align: center;}
  #hallOfFame img {margin:auto; margin-top:1rem; margin-bottom: 2rem;}
  #hallOfFame .fameFlex .flexDiv {width:48%;}
  #hallOfFame .fameFlex {display: flex; flex-wrap: wrap; justify-content: space-between; width:80%; margin: auto auto 2vw auto;}

  #hallOfFame .sectionDiv > :nth-child(1) {  background: repeating-conic-gradient(#000 0% 25%, transparent 0% 50%) 50% /
    20px 20px;}
  #hallOfFame .sectionDiv > :nth-child(3) {  background: repeating-conic-gradient(#000 0% 25%, transparent 0% 50%) 50% /
    20px 20px;}

  @media screen and (max-width: 1167px) {
    #hallOfFame .fameFlex {flex-direction: column;}
    #hallOfFame .fameFlex .flexDiv {width:80%; margin:auto;}
  }

  @media screen and (max-width: 1024px) {
  #hallOfFame .fameFlex .flexDiv {width:100%;}
  }
  @media screen and (max-width: 800px) {
  #hallOfFame .fameFlex {width:90%;}
  }
  /* Video */
  #pastStreams h2 {color:#000; margin:auto; text-align: center;}  
  #pastStreams .flexDiv h2 {margin-bottom:32px;}
  #pastStreams .flexDiv {margin-bottom: 64px;} 
  #pastStreams .videoContainer {
    position: relative;
    padding-bottom:33%;
    text-align: center;
    height:0;
  }
  #pastStreams .videoContainer iframe {
    position:absolute;
    left:21%;
    top:0;
    width:60%;
    height:100%;
  }

  #pastStreams .sectionDiv > :nth-child(1) {  background: repeating-conic-gradient(#000 0% 25%, transparent 0% 50%) 50% /
    20px 20px;}
  #pastStreams .sectionDiv > :nth-child(3) {  background: repeating-conic-gradient(#000 0% 25%, transparent 0% 50%) 50% /
    20px 20px;}

  @media screen and (max-width: 1000px) {
    #pastStreams .videoContainer {
      padding-bottom:51%;
    }
    #pastStreams .videoContainer iframe {
      width:95%;
      height:100%;
      left: 0.7rem;
    }

  }

  #calendar h2 {color:#000; margin:auto; text-align: center;}

  #calendar .sectionDiv > :nth-child(1) {  background: repeating-conic-gradient(#000 0% 25%, transparent 0% 50%) 50% /
    20px 20px;}
  #calendar .sectionDiv > :nth-child(3) {  background: repeating-conic-gradient(#000 0% 25%, transparent 0% 50%) 50% /
    20px 20px;}

  #calendar .calendarImg {margin:auto; width:30%; margin-bottom:2rem;}

  @media screen and (max-width: 1000px) {
  #calendar .calendarImg {margin:auto; width: 80%; margin-bottom:2rem;}
  }
  
  /* Standings */
  .typeTitle {color:var(--clr-white); width:88%; margin:auto; text-align: center; margin-bottom: 32px;}    

  @media screen and (max-width: 800px) {
  .typeTitle {margin-bottom: 32px;}    
  }

  #standings table {
    border: 2px solid var(--clr-white);
    width: 60%;
    margin: auto;
    margin-bottom: 3rem;
    }    

    #standings table.constructors {
      width: 50%;
    }

  #standings th {
    background-color:var(--clr-black);
    min-width: 1.8rem;
    }
  #standings th>h4 {
    margin: 2% 0% 4% 0%;
    color:var(--clr-white);
    font-weight: bold;
    }
    
  #standings td {
    text-align: center;
    color:var(--clr-black);
    font-weight: 600;
    border-bottom: 1px solid var(--clr-white);
    background-color:var(--clr-white);
    }

    #standings tr {
      height:4rem;
    }
    #standings .positionCol {
      width:12%;
    }
    #standings .teamCol {
      width: 6rem;
    }
    #standings .blankCol {
      width:14vw;
    }
    #standings td.blankCol h3 {
      color: #000;
      margin-bottom:0;
      letter-spacing: normal;
    }
  
    #standings img {width:100%; margin: auto;}

    #standings .teamImg img {width:75%;}
    #standings .flexBox {display: flex; align-items: center;}
    #standings h5 {flex:2; font-size:1rem;}
    #standings h2 {margin-bottom: 0;}
    #standings .teamImg {flex:1;}
    #standings p {
      border-radius: 20px;
      background-color: var(--clr-black);
      padding: 5% 0%;
      margin-bottom: 0;
      width: 2.5rem;
      margin: auto;
      font-weight: 600;
      color:var(--clr-white);
      font-size:12px;
      line-height: 28px;;
    }
    #standings .fastestCol {
      background-color:#684bc8;
      color:var(--clr-white);
      border-radius: 10%;
      width:6%;
    }

    #standings .combinedTotal {
      background-color:lightgray;
    }

    #standings .poleCol {
      color:var(--clr-white);
      border-radius: 10%;
      width:6%;
    }

    #standings .driverList {width:15rem;}
    #standings .standingsImg {margin:auto; width: 65%; margin-bottom:3rem;}
    #standings .constructorsImg {margin:auto; width: 30%; margin-bottom:3rem;}

    @media screen and (max-width: 1315px) {
      #standings table {
        width:90%;
      }
      #standings table.constructors {
        width: 90%;
      }
    
      }

    @media screen and (max-width: 800px) {
      #standings table {
        width:96%;
        
      }
      #standings table.constructors {
        width: 90%;
      }

      #standings .columnHide {display: none;}

      #standings th {
        min-width: 3rem;
        }
        #standings p {
          width: 1.8rem;
        }

        #standings .driverList {width:14rem;}
        #standings p {line-height: 22px;}
      }

      @media screen and (max-width: 700px) {
        #standings .indivTable h5 {display: none;}
        #standings .indivTable th {min-width: 3.5rem;}
        #standings .indivTable .driverList {width:11rem;}
        #standings .indivTable .teamCol {width:8rem;}
        #standings table {width:90%;}
        #standings .standingsImg {width: 95%;}
        #standings .constructorsImg {width: 95%;}
      }

      .tabs {     
        justify-content: space-evenly;
        display: flex;
        width:30vw;
        gap: 1vw;
        margin: 3vw auto;
      }

      .tabs button {background-color: #000;
        color: #fff;
        padding: 4%;
        font-size: 24px;
        border-radius: 1.5vw;
        border: solid 2px #fff;
      min-width: fit-content;
    }

      .tabs button:active {cursor: pointer; color: #000; background-color:#fff;}
      .tabs button:hover {cursor: pointer; color: #000; background-color:#fff;}

       
      .tabs button:disabled {background-color: #fff;
        color: #000;
        padding: 4%;
        font-size: 24px;
        border-radius: 1.5vw;
        min-width: fit-content}
 
        #calendar .tabs button {background-color: #fff;
          color: #000;
          border: solid 2px #000;
          padding: 4%;
        }
  
        #calendar .tabs button:hover {color: #fff; background-color:#000;}
           
        #calendar .tabs button:disabled {background-color: #000; color:#fff;}     
        
        .tabs button.activeTab  {background-color: #fff; color:#000;}
        #calendar .tabs button.activeTab {background-color: #000; color:#fff;}

      #calendar .tabs {     
        justify-content: space-evenly;
        display: flex;
        gap: 0vw;
        margin: 3vw auto;}


        @media screen and (max-width: 1400px) {
          .tabs {     
            width: 58%;
            gap: 1.5vw;}
        }  
        
        @media screen and (max-width: 900px) {
          #calendar .tabs {     
            gap:1.5vw;
            justify-content: center;
            width: min-content;
        }
      } 

        @media screen and (max-width: 800px) {
          .tabs button {font-size:20px; padding:6%; margin-bottom: 4vw;}
          .tabs button:disabled {font-size:20px; padding:6%;}
          .tabs {     
            width: min-content;
            justify-content: center;
            gap: 2.5vw;}

            #calendar .tabs button {padding:8%;}
            #calendar .tabs {     
              gap: 2.5vw;}
        }

        #calendar .welcomeImage {width:50%; margin: 4% auto;}

        @media screen and (max-width: 900px) {
        #calendar .welcomeImage {width:90%; margin: auto; margin-bottom: 10%;}
        }

